import logo from './assets/logo.jpg';
import './App.css';

import Contactform from './components/Contactform';
import About from './components/About';
import Services from './components/Services';
import Footer from './components/Footer'

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      <div>        
        <img src={logo} className="App-logo" alt="logo" />        
      </div>
      <h3>3329 Business Circle,
      North Charleston, SC 29418</h3>
      <hr />
      <div className="App-container">
        <div className="App-container-item App-about">
          <About /> 
          <br />
          <hr />
          <br />
          <Services />
        </div>        
        <div className="App-container-item App-contact">
          <Contactform/>
        </div>
      </div>
      <hr />
      <Footer />      
    </div>
  );
}

export default App;
