import React from "react"

export default function About()
{
    return (
        <div>
            <h1>About Us</h1><br />
            <p>We at Foresight Surveying are happy to have this opportunity to introduce ourselves. 
                Foresight Surveying is a Land Surveying Company located in South Carolina's beautiful Lowcountry. 
            </p><br />
            <p>We at Foresight Surveying sincerely appreciate all our clients, and treat each client as if they were our only client.  
                We believe in keeping our clients’ needs foremost, maintaining honesty and integrity throughout a project and take 
                pride in offering a quality service.  
            </p>
            <p><br />
                Should you have any questions about what we can offer you, or if you would like to request a quote on your project, 
                please contact us.  We would be happy to discuss your Land Surveying needs and how Foresight Surveying can serve you.  
                We look forward to the opportunity to work for you, and do our part to make your project, large or small, a success.
            </p>
          </div>
    )
}