// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAAGO75IM-i9Ua6T1UO9E_2fbaSA3bHsP4",
  authDomain: "foresightsurveyingllc-e80df.firebaseapp.com",
  projectId: "foresightsurveyingllc-e80df",
  storageBucket: "foresightsurveyingllc-e80df.appspot.com",
  messagingSenderId: "58753380005",
  appId: "1:58753380005:web:5e02d17682ba40d0eba74c",
  measurementId: "G-2XRTT2LR0V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const db = getFirestore(app)

