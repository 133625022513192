import React, { useState, useEffect } from "react";

import './Footer.css'

export default function Footer()
{
    const [date , setDate] = useState();

    const getYear = () =>  setDate(new Date().getFullYear())

    useEffect(() => {
        getYear();
    }, [])

    return (
        <div className="footer">
            <ul>
                <li>
                    Copyright &copy; {date} Foresight Surveying LLC
                </li>
                <li>
                    Designed by <a href="https://rkpatech.com" target="_blank" rel="noreferrer">RK Tech</a>
                </li>
            </ul>
        </div>
    )
}