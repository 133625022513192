import React from "react"

import './Services.css'

export default function Services()
{
    return (
        <div className="Services">
            <h1>Professional Surveying Services</h1> <br />
            <ul>
                <li>Engineering Surveys</li>
                <li>Construction Layout/Staking</li>
                <li>Asbuilt Surveys</li>
                <li>Record Drawings</li>
                <li>GIS</li>
                <li>ALTA/ACSM Surveys</li>
                <li>Boundary Surveys</li>
                <li>Subdivision Plats</li>
                <li>Topographic Surveys</li>
                <li>Tree Surveys</li>
                <li>Route Surveys</li>
                <li>Site Plans</li>
                <li>Elevation Certificates</li>
                <li>Wetland Surveys</li>
            </ul>
        </div>
    )
}