import React, { useRef } from "react"
import { useState } from "react"
import { db } from "../firebase/config"
import { collection, addDoc } from 'firebase/firestore'
import { toast, ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import ReCAPTCHA from "react-google-recaptcha"

import './Contactform.css'

export default function Contactform()
{
    const [first, setFirst] = useState('')
    const [last, setLast] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [company, setCompany] = useState('')
    const [address, setAddress] = useState('')
    const [service, setService] = useState('')
    const [method, setMethod] = useState('Email Address')
    const [loading, setLoading] = useState(false)
    const [isCaptchaSuccessful, setIsCaptchaSuccessful] = useState(false)

    const url = '/api/email'
    const captchaRef = useRef(null)
    // const dotenv = require("dotenv");

    const handleSubmit = async (e) => {
        e.preventDefault()
        // const token = captchaRef.current.getValue();        
        addDoc(collection(db, 'contacts'), {
            name: first + " " + last,
            email: email,
            phone: phone,
            company: company,
            address: address,
            service: service,
            method: method
        })
        const subject = "New Contact Form Submission from " + first + " " + last
        const message = '<p>Name: ' + first + " " + last + '</p>\n' +
                         '<p>Email: ' + email + '</p>\n' +
                         '<p>Phone: ' + phone + '</p>\n' +
                         '<p>Company: ' + company + '</p>\n' +
                         '<p>Address: ' + address + '</p>\n' +
                         '<p>Service: ' + service + '</p>\n' +
                         '<p>Method: ' + method + '</p>'        
        if(!email || !phone || !company || !address || !service) {
            return toast.error('Please fill in all fields')
        }
        console.log(isCaptchaSuccessful)
        if(!isCaptchaSuccessful) {            
            return toast.error('Failed reCaptcha check')
        }
        try {
            captchaRef.current.reset();
            setLoading(true);
            setIsCaptchaSuccessful(false)
            const { data } = await axios.post(url, {                
                subject,
                message,
            },
            )
            setLoading(false)
            toast.success(data.message)
        } catch (err) {
            setLoading(false)
            toast.error(
                err.response && err.response.data.message
                ? err.response.data.message
                : err.message
            )

        }
    }

    const handleChange = (event) => {
        setMethod(event.target.value)
    }

    const onChange = (value) => {
        if (!value) {
            setIsCaptchaSuccessful(false)
        }
        else {
            setIsCaptchaSuccessful(true)
        }
        console.log(isCaptchaSuccessful)
    }

    return (
        <div>
            <ToastContainer position="bottom-center" limit={1} />
            <form onSubmit={handleSubmit} className="contact-form">
                <h2>Contact Us</h2><br />
                <ul>                
                    <li>Email: <a href="mailto:info@foresightsurveyingllc.com">info@foresightsurveyingllc.com</a></li>
                    <li>Phone: <a href="tel:8432033401">(843)203-3401</a></li>
                    <li>Fax:   (843)408-4645</li>
                </ul>
                <label>
                    <span>First Name:</span>
                    <input 
                        required
                        type="text"
                        onChange={(e) => setFirst(e.target.value)}
                        value={first}
                    />
                </label>
                <label>
                    <span>Last Name:</span>
                    <input 
                        required
                        type="text"
                        onChange={(e) => setLast(e.target.value)}
                        value={last}
                    />
                </label>
                <label>
                    <span>Email:</span>
                    <input 
                        required
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                    />
                </label>
                <label>
                    <span>Phone Number:</span>
                    <input 
                        required
                        type="text"
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                    />
                </label>
                <label>
                    <span>Company:</span>
                    <input 
                        required
                        type="text"
                        onChange={(e) => setCompany(e.target.value)}
                        value={company}
                    />
                </label>
                <label>
                    <span>Address:</span>
                    <input 
                        required
                        type="text"
                        onChange={(e) => setAddress(e.target.value)}
                        value={address}
                    />
                </label>
                <label>
                    <span>Product / Service:</span>
                    <input 
                        required
                        type="text"
                        onChange={(e) => setService(e.target.value)}
                        value={service}
                    />
                </label>
                <label>
                    <span>Best Way to Contact You:</span>
                    <select value={method} onChange={handleChange}>
                        <option value="Email">Email</option>
                        <option value="Phone">Phone</option>
                    </select>
                    {/* <input 
                        required
                        type="text"
                        onChange={(e) => setMethod(e.target.value)}
                        value={method}
                    /> */}
                </label>
                <div className="g-recaptcha">
                    <ReCAPTCHA 
                        sitekey="6Le8M6EjAAAAALOR7hxvoyY0EObEYZ1igH5X-slY"
                        ref={captchaRef}             
                        onChange={onChange}       
                    />
                </div>
                <br />
                <button type="submit"
                        disabled={loading}
                        className="btn">
                        {loading ? 'Sending...' : 'Submit'}
                </button>            
            </form>
        </div>
    )
}